import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import GridContainer from '../GridContainer';
import ImageLink from './ImageLink';
import { alternateGridCol } from '../grid';

export default function SCSec() {
    const secs = [
        {
            title: 'Taurus HQ: Go testing + finesse',
            image: '/taurus/feb-7-talk/files/neo-matrix.gif',
            linkTo: '/taurus/feb-7-talk/#1',
            height: '150',
        },
        {
            title: 'Time Dilation in Go1.11, Go1.12',
            image: '/retros/go1.11-time-dilation/images/einstein-time-dilation.webp',
            linkTo: '/scsec/go1.11-time-dilation',
            height: '150',
        },
        {
            title: 'PowerOf2 Loop Meets Integer Overflow',
            image: '/advisory/pow2-loop-overflow/overflow.gif',
            linkTo: '/advisory/pow2-loop-overflow',
            height: '150',
        },
        {
            title: 'Cosmos December 2023 report',
            image: '/cosmos/hardening/images/cosmos-wordmark-padded-dark.png',
            linkTo: '/cosmos/dec2023',
            height: '150',
        },
        {
            title: 'ics23 Go v1 audit',
            image: '/atomModel.png',
            linkTo: '/scsec/cosmos-ics23-audit-v1',
            height: '150',
        },
        {
            title: 'Advisory: varint decoding',
            image: '/cerberus.gif',
            linkTo: '/advisory/varint-decode-limitless',
            height: '150',
        },
        {
            title: 'Audit: American Express Earlybird audit',
            image: '/amex/logo.svg',
            linkTo: '/scsec/amex-earlybird-v1',
            height: '150',
        },
        {
            title: 'Advisory: Go XSS from string concatenation and text/template',
            image: '/images/gophers-dissecting.jpeg',
            linkTo: '/advisory/go-xss-concat-text-template',
            height: '100',
        },
        { 
            title: 'Cosmos Hardening Guide',
            image: '/cosmos/hardening/images/cosmos-wordmark-padded-dark.png',
            linkTo: '/scsec/cosmos-hardening',
            height: '100',
        },
        {
            title: 'Software Supply Chain Audit v1',
            image: '/cosmos/hardening/images/cosmos-wordmark-padded-dark.png',
            linkTo: '/scsec/cosmos-v1',
            height: '100',
        },
        {
            title: 'celestia/torch v1 audit',
            image: '/celestia/images/logo.png',
            linkTo: '/scsec/celestia-torch-v1',
            height: '50',
            lightBackground: true,
            padImage: true,
        },
        {
            title: 'celestia/nmt v1 audit',
            image: '/celestia/images/logo.png',
            linkTo: '/scsec/celestia-nmt-v1',
            height: '50',
            lightBackground: true,
            padImage: true,
        },
        {
            title: 'cosmos/gaia Q4 2022 pre-Rho audit',
            image: '/cosmos/gaia/logo.png',
            linkTo: '/scsec/cosmos-gaia-rho-v1',
            height: '100',
            lightBackground: true,
            padImage: true,
        },
        {
            title: 'Tendermint P2P v1 audit',
            image: '/tendermint/images/tendermint-logo-black.png',
            linkTo: '/scsec/tendermint-p2p-v1',
            height: '50',
        },
        {
            title: 'dydx NPM compromise advisory',
            image: '/dydx/npm-compromise/images/dydx-logo.svg',
            linkTo: '/scsec/dydx-npm-compromise',
            height: '50',
        },
        {
            title: 'Go Coding Guide',
            image: '/cosmos/hardening/images/cosmos-wordmark-padded-dark.png',
            linkTo: '/scsec/cosmos-go-coding-guide',
            height: '50',
        },
        {
            title: 'UNIX file permissions zombie',
            image: '/unix/file-handle-perms/images/unix-file-handle-logo.png',
            linkTo: '/scsec/unix-file-handle-perms',
            height: '150',
        }
    ]
    return (
        <GridContainer>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h3" gutterBottom>SCSEC</Typography>
                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                    Orijtech Security has a mandate of wholly securing software infrastructure!
                    Instead of always working to defend and catch security issues, a much more pragmatic and
                    holistic approach is to build secure components from the ground up.
                    Perhaps you might have seen the USA Presidency Biden-Harris Administration's WhiteHouse
                    issue a novel Executive Order on Cybersecurity per
                    <a href="https://www.whitehouse.gov/briefing-room/presidential-actions/2021/05/12/executive-order-on-improving-the-nations-cybersecurity/">
                        executive order on improving the nations cybersecurity
                    </a> such as Executive Order (E.O) 14028; incidentally many of the ideas mandated in that E.O are what we had planned for!
                    Here are some detailed supply chain security reports we've produced.
                </Typography>
                <Box
                    sx={[
                        {display: 'grid', gap: '1rem 2rem' },
                        (t) => alternateGridCol(t),
                    ]}
                >
                    {secs.map((props, i) => (
                       <Link
                            key={i}
                            underline='none'
                            color="unset"
                            component={RouterLink}
                            to={props.linkTo}
                        >
                            <ImageLink {...props} />
                        </Link> 
                    ))}
                </Box>
            </Grid>
        </GridContainer>
    );
}
